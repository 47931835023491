jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    if ($(window).width() < 1024) {
        $(".has-sub .toggle").on("click", function () {
            $(this).next().slideToggle();
            $(this).toggleClass("fa-chevron-down fa-chevron-up");
        });
    } else {
        diagramJsInit();
    }

    collapseComponent();
    newsSlider();
    productSlider();
    scrollNavInit();
    accordionTableInit();
    filterReportInit();
    historySliderInit();
    accordionContentInit();
    homeInsuranceTabInit();
    homeAwardSliderInit();
});

var scrollToElement = function (el, ms) {
    var speed = ms ? ms : 2000;
    $("html,body").animate(
        {
            scrollTop: $(el).offset().top - $("header").height(),
        },
        speed
    );
};

function collapseComponent() {
    $(document).on("click", ".collapse-trigger", function () {
        $(this).next().slideToggle();
        $(this).toggleClass("active");
        return false;
    });
}

function accordionContentInit() {
    $(".accordion-trigger").each(function () {
        let _this = $(this);
        let em = $(this).find("em");
        let span = $(this).find("span");

        _this.on("click", function (e) {
            e.preventDefault();
            _this.toggleClass("active");
            _this.next().slideToggle();
            $(em).toggleClass("fa-long-arrow-down fa-long-arrow-up");
            if (_this.hasClass("active")) {
                $(span).text(_this.data("text-less"));
            } else {
                $(span).text(_this.data("text-more"));
            }
        });
    });
}

const searchMapping = new MappingListener({
    selector: ".search-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-top-right",
    desktopMethod: "prependTo",
    breakpoint: 1024,
}).watch();

const menuTopMapping = new MappingListener({
    selector: ".menu-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-bottom-wrap .container",
    desktopMethod: "appendTo",
    breakpoint: 1024,
}).watch();

function accordionTableInit() {
    let applyForm = $(".job-apply-form");
    let allTrigger = $(".table.is-accordion .trigger");
    let allBody = $(".table.is-accordion tr:not(.trigger)");
    allTrigger.each(function (index) {
        let _this = $(this);
        let jobTitle = _this.next().find("h3").text();
        if ((index + 1) % 2 === 0) {
            _this.addClass("is-even");
        }
        _this.on("click", function () {
            if (_this.hasClass("active")) {
                _this.removeClass("active");
                _this.nextUntil(".trigger").removeClass("is-open");
            } else {
                allTrigger.removeClass("active");
                allBody.removeClass("is-open");
                _this.addClass("active");
                _this.nextUntil(".trigger").addClass("is-open");
            }
            if (applyForm.length > 0) {
                _this.next().find(".job-apply-form-wrap").append(applyForm);
                applyForm.find(".form-job-title input").val(jobTitle);
                applyForm.show();
            }
            _this
                .next()
                .find(".toggle")
                .on("click", function () {
                    _this.trigger("click");
                });
        });
        if (index === 0) {
            _this.trigger("click");
        }
    });
}

function scrollNavInit() {
    $(".scroll-nav .toggle-nav").on("click", function () {
        $(this).toggleClass("active");
        $(this).next().slideToggle();
    });
}

function diagramJsInit() {
    $(".diagram-item.is-child").each(function () {
        var _this = $(this);
        var siblings = $(".diagram-item.is-child").not(_this);
        var allSub = $(siblings).find(".sub-apartment");
        var sub = $(this).find(".sub-apartment");

        _this.on("click", function () {
            siblings.removeClass("active");
            allSub.fadeOut();
            _this.addClass("active");
            $(sub).fadeIn();
        });
    });
}

function homeInsuranceTabInit() {
    $(".home-insurance-tab .product-sub-slider").each(function () {
        let _this = $(this);
        let swiperContainer = _this.find(".swiper-container");
        let sliderPrevBtn = _this.find(".swiper-prev");
        let sliderNextBtn = _this.find(".swiper-next");
        let swiper = new Swiper(swiperContainer, {
            slidesPerView: "auto",
            spaceBetween: 4,
            loop: true,
            navigation: {
                prevEl: sliderPrevBtn,
                nextEl: sliderNextBtn,
            },
            breakpoints: {
                1024: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                },
            },
        });
    });

    $(".home-insurance-tab .tabs").tabslet({
        container: "#insuranceTabContainer",
    });
}

function homeAwardSliderInit() {
    let awardYearSlider = new Swiper(".award-year-slider .swiper-container", {
        slidesPerView: 3,
        initialSlide: 1,
        spaceBetween: 80,
        centeredSlides: true,
        direction: "vertical",
        observeParents: true,
        observer: true,
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            prevEl: ".award-year-slider .swiper-prev",
            nextEl: ".award-year-slider .swiper-next",
        },
        breakpoints: {
            1024: {
                direction: "horizontal",
                spaceBetween: 40,
            },
        },
    });

    let awardContentSlider = new Swiper(
        ".award-content-slider .swiper-container",
        {
            slidesPerView: 1,
            initialSlide: 1,
            observeParents: true,
            observer: true,
            breakpoints: {
                1024: {
                    spaceBetween: 20,
                },
            },
        }
    );

    awardYearSlider.controller.control = awardContentSlider;
    awardContentSlider.controller.control = awardYearSlider;
}

function newsSlider() {
    $(".news-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("news-slider-" + index);
        $this.find(".swiper-prev").addClass("news-slider-prev-" + index);
        $this.find(".swiper-next").addClass("news-slider-next-" + index);

        var newsSlider = new Swiper(
            ".news-slider-" + index + " .swiper-container",
            {
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".news-slider-prev-" + index,
                    nextEl: ".news-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3.25,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.25,
                        spaceBetween: 15,
                    },
                    576: {
                        slidesPerView: 1.5,
                        spaceBetween: 10,
                    },
                },
            }
        );
    });
}

function productSlider() {
    $(".product-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("product-slider-" + index);
        $this.find(".swiper-prev").addClass("product-slider-prev-" + index);
        $this.find(".swiper-next").addClass("product-slider-next-" + index);

        var productSlider = new Swiper(
            ".product-slider-" + index + " .swiper-container",
            {
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".product-slider-prev-" + index,
                    nextEl: ".product-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3.25,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.25,
                        spaceBetween: 15,
                    },
                    576: {
                        slidesPerView: 1.5,
                        spaceBetween: 10,
                    },
                },
            }
        );
    });
}

function filterReportInit() {
    $(".filter-report-container").each(function () {
        let _this = $(this);
        let colCount = 1;
        let sliderContainer = _this.find(
            ".filter-report-slider .swiper-container"
        );
        let sliderPrevBtn = _this.find(".filter-report-slider .swiper-prev");
        let sliderNextBtn = _this.find(".filter-report-slider .swiper-next");
        if ($(sliderContainer).data("cols") !== undefined) {
            colCount = $(sliderContainer).data("cols");
        }
        let reportSlider = new Swiper(sliderContainer, {
            slidesPerView: 2,
            slidesPerColumn: colCount,
            spaceBetween: 30,
            freeMode: true,
            navigation: {
                prevEl: sliderPrevBtn,
                nextEl: sliderNextBtn,
            },
            breakpoints: {
                1024: {
                    slidesPerColumn: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                    slidesPerColumn: 2,
                },
            },
        });
    });
}

function historySliderInit() {
    let historyYearSlider = new Swiper(
        ".history-year-slider .swiper-container",
        {
            slidesPerView: 6,
            spaceBetween: 15,
            observeParents: true,
            observer: true,
            slideToClickedSlide: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                1024: {
                    slidesPerColumn: 5,
                },
                768: {
                    slidesPerView: 4,
                },
                576: {
                    slidesPerView: 3,
                },
            },
        }
    );
    let historyContentSlider = new Swiper(
        ".history-content-slider .swiper-container",
        {
            spaceBetween: 15,
            observeParents: true,
            observer: true,
            thumbs: {
                swiper: historyYearSlider,
            },
            on: {
                slideChange: function () {
                    let activeIndex = this.activeIndex + 1;

                    let nextSlide = $(
                        `.history-year-slider .swiper-slide:nth-child(${
                            activeIndex + 1
                        })`
                    );
                    let prevSlide = $(
                        `.history-year-slider .swiper-slide:nth-child(${
                            activeIndex - 1
                        })`
                    );

                    if (
                        nextSlide &&
                        !nextSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slideNext();
                    } else if (
                        prevSlide &&
                        !prevSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slidePrev();
                    }
                },
            },
        }
    );
}

let bannerHomeSlider = new Swiper(".banner-home-slider .swiper-container", {
    speed: 1300,
    effect: "fade",
    fadeEffect: { crossFade: true },
    loop: true,
    autoPlay: {
        delay: 4000,
    },
    navigation: {
        prevEl: ".banner-home-slider .swiper-prev",
        nextEl: ".banner-home-slider .swiper-next",
    },
});

let feedbackHomeSlider = new Swiper(".feedback-slider .swiper-container", {
    slidesPerView: 2,
    spaceBetween: 65,
    navigation: {
        prevEl: ".feedback-slider .swiper-prev",
        nextEl: ".feedback-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 30,
            initialSlide: 1,
            centeredSlides: true
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 20,
            initialSlide: 1,
            centeredSlides: true
        }
    },
});
